/* Add this CSS to your stylesheet */
.search-box {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .search-input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
    width: 80%;
    max-width: 400px;
  }
  
  .search-button {
    padding: 10px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 5px 5px 0;
    background-color: #8200dd;
    color: white;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #49009b;
  }
  
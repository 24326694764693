.modern-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modern-loader {
  display: flex;
  gap: 12px;
}

.modern-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(145deg, #6366f1, #4f46e5);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
  animation: modernBounce 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

.modern-dot:nth-child(1) {
  animation-delay: -0.32s;
}

.modern-dot:nth-child(2) {
  animation-delay: -0.24s;
}

.modern-dot:nth-child(3) {
  animation-delay: -0.16s;
}

.modern-dot:nth-child(4) {
  animation-delay: -0.08s;
}

@keyframes modernBounce {
  0%,
  80%,
  100% {
    transform: scale(0.8) translateY(0);
  }
  40% {
    transform: scale(1.2) translateY(-16px);
    box-shadow: 0 8px 24px rgba(99, 102, 241, 0.4);
  }
}

/* Dark mode */
@media (prefers-color-scheme: dark) {
  .modern-loader-overlay {
    background: rgba(17, 24, 39, 0.95);
  }

  .modern-dot {
    background: linear-gradient(145deg, #7de76f, #22be0d);
    box-shadow: 0 4px 12px rgba(129, 140, 248, 0.3);
  }
}

/* Glass morphism variant */
.modern-loader-glass .modern-dot {
  background: rgba(165, 167, 247, 0.8);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

/* Neon variant */
.modern-loader-neon .modern-dot {
  background: #827ec5;
  box-shadow: 0 0 12px #6366f1, 0 0 24px #818cf8;
  animation: modernNeonBounce 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    infinite;
}

@keyframes modernNeonBounce {
  0%,
  80%,
  100% {
    transform: scale(0.8) translateY(0);
    box-shadow: 0 0 12px #6366f1, 0 0 24px #818cf8;
  }
  40% {
    transform: scale(1.2) translateY(-16px);
    box-shadow: 0 0 24px #6366f1, 0 0 48px #818cf8;
  }
}

/* Minimal variant */
.modern-loader-minimal .modern-dot {
  background: #6366f1;
  box-shadow: none;
  transform-origin: center bottom;
}

/* Elastic variant */
.modern-loader-elastic .modern-dot {
  animation: modernElastic 1.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite;
}

@keyframes modernElastic {
  0%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.4) scaleY(0.8);
  }
}
